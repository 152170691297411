import type { PolymorphicRef, PolymorphicProps, PolymorphicPropsWithRef, PolymorphicComponent } from "@/types";
import type { ElementType } from "react";

import { forwardRef } from "react";

/**
 * polyMorphComponent HOC that abstracts over an HTML element or React component, and enable to change
 * the underlying DOM node of a component.
 *
 * The idea is to have a component that can could be "morphed" into another visual component
 * while retains its internal functionalities & a list of overridable props.
 *
 * This is useful for design-system-level components that needs to take a default tag but could be transformed in use.
 * polymorphComponent comes fully with TypeScript casting to ensure that whatever the new DOM is, the accompanying
 * attributes are matched.
 *
 * @param P the custom (non-HTML) props of the passed in React component (default = {})
 * @param E the element type to extends from, either an HTML element or a React component.
 * @param T the default element type to fallback on, especially useful if only a limited set is set to E.
 */
export const polymorphComponent = <P extends object = object, E extends ElementType = ElementType, T extends E = E>(
    Component: T,
    overrideProps = (props: PolymorphicProps<E, P>) => props
) => {
    const PolymorphableComponent = forwardRef(
        <C extends ElementType = E>({ as, ...props }: PolymorphicPropsWithRef<C, P>, ref?: PolymorphicRef<C>) => {
            const ComponentToRender = as || Component;

            return (
                <ComponentToRender
                    ref={ref}
                    {...(overrideProps(props as PolymorphicProps<E, P>) as JSX.LibraryManagedAttributes<E, P>)}
                />
            );
        }
    ) as unknown as PolymorphicComponent<P, E, T>;

    return PolymorphableComponent;
};
