interface UseCookieOptions {
    keys: string[];
}

interface CookieValues {
    [key: string]: string | null | undefined;
}

export const useCookie = ({ keys }: UseCookieOptions) => {
    const cookies = document.cookie.split(";");

    const values: CookieValues = {};
    keys.forEach((key) => {
        const cookie = cookies.find((c) => c.trim().startsWith(`${key}=`));
        values[key] = cookie ? cookie.split("=")[1] : null;
    });

    return values;
};
