import type { Country } from "./type";

import { axios } from "@/libs/axios";

export const getAllCountries = async (): Promise<Country[]> => {
    const { data } = await axios.get<Country[]>("/country");

    return data;
};

export const getAllTINCountries = async (): Promise<Country[]> => {
    const { data } = await axios.get<Country[]>("/eu/countriesRequiringTIN");
    data.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return data;
};
