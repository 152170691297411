/**
 * Event handler chain merge. Functions are run in sequence from left to right.
 *
 * @param fnArray an array of event handlers, extending type from an array of void functions.
 *
 * @example
 * <PdsButton onClick={ex(onChildClick, onParentClick)} />
 * =
 * <PdsButton
 *  onClick={(e) => {
 *      if (onChildClick) {
 *          onChildClick(e)
 *      }
 *
 *      if (onParentClick) {
 *          onParentClick(e)
 *      }
 *  }}
 * />
 */
export const eventHandlerMerge = <T extends (...args: never[]) => void = (...args: never[]) => void>(
    ...fnArr: (T | undefined | null)[]
): T | undefined => {
    const nonNullishFnArray = fnArr.filter(Boolean);

    return nonNullishFnArray.length > 0
        ? (((args: never) => {
              nonNullishFnArray.forEach((fn) => {
                  if (fn) {
                      fn(args);
                  }
              });
          }) as T)
        : undefined;
};

export { eventHandlerMerge as ex };
