(function (w, d) {
    function r() {
        var scriptSrc =
            import.meta.env.VITE_NODE_ENV === "staging" || import.meta.env.VITE_NODE_ENV === "development"
                ? `https://cdn-pci.cashier-test.com/PraxisGate.js`
                : `https://cdn.praxisgate.com/PraxisGate.js`;
        var s = document.createElement("script");
        s.src = scriptSrc + "?t=" + new Date().getTime();
        (d.body || d.head).appendChild(s);
    }

    w.loadPraxisHpfScript = r;
})(window, document);
