import ls from "localstorage-slim";
import { Outlet, useLocation } from "react-router-dom";

import { useDevInfo } from "@/query/dev";
import { useCurrentUser } from "@/query/users";
import { useUserAuth } from "@/query/auth";
import { ENABLE_USER } from "@/query/type";

import { LoadingLayout } from "@/features/loadingLayout";

import { NonMatchRedirect } from "./NonMatchRedirect";

export const AutoRedirectRoute = () => {
    const {
        devInfo: { authMode },
    } = useDevInfo();
    const { state } = useLocation();

    const { auth } = useUserAuth();
    const { user, isLoading: isUserLoading } = useCurrentUser();
    /**
     * Basically, bypassing all auth/user checks.
     */
    if (authMode === "skip" || state?.force) {
        return <Outlet />;
    }

    /*** Keeping user on loading page while loginViaGoogle is in progress. */
    if (ls.get(ENABLE_USER) ?? false) {
        return <NonMatchRedirect to="/loading" />;
    }

    /** Error & loading state handling. */
    if (isUserLoading) {
        return <LoadingLayout />;
    }

    if (auth && !auth?.emailVerified) {
        return <NonMatchRedirect to={`/verify-email?email=${auth?.email}`} />;
    }

    /**
     * Optimistically redirect once user is registered.
     */
    if (user) {
        return <NonMatchRedirect to="/dashboard" />;
    }

    return <Outlet />;
};
