import { useMemo } from "react";

import { isEU } from "@/libs/processEnv";

import { useCurrentUser } from "@/query/users";

export const useDemo = () => {
    const { user } = useCurrentUser();
    const isDemo = useMemo(() => user?.role === "Demo", [user?.role]);
    const isKycApproved = useMemo(() => user?.isKycCompleted, [user?.isKycCompleted]);
    const isKycPending = useMemo(() => !user?.isKycCompleted, [user?.isKycCompleted]);
    const isEUFailed = useMemo(() => isEU() && !user?.isAssessmentPassed, [user?.isAssessmentPassed]);
    const isDemoEU = useMemo(() => isEU() && user?.role === "Demo", [user?.role]);
    const isCanRefer = useMemo(() => !!user?.referCode, [user?.referCode]);
    const isElectiveProfessionalApproved = useMemo(
        () => isEU() && user?.electiveProfessionalStatus === 2,
        [user?.electiveProfessionalStatus]
    );
    const isOnHold = useMemo(() => user?.status === "OnHold", [user?.status]);
    const isToxic = useMemo(() => user?.infractionStatus === "Toxic", [user?.infractionStatus]);
    return {
        isDemo,
        isKycApproved,
        isKycPending,
        isCanRefer,
        isEUFailed,
        isDemoEU,
        isElectiveProfessionalApproved,
        isOnHold,
        isToxic,
    };
};
