import type { ElementType } from "react";
import type { PolymorphicProps } from "@/types";

import { tx } from "@/libs/tailwindMerge";
import { polymorphComponent } from "@/libs/polymorphComponent";

export type AprCardProps = PolymorphicProps<ElementType>;

export const AprCard = polymorphComponent("div", ({ className, walletMethods = false, ...props }) => ({
    className: tx(
        "relative rounded-xl",
        { "shadow-xl bg-background border border-background p-6 backdrop-blur-lg": !walletMethods },
        className
    ),
    ...props,
}));
