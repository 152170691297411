import type { UserCredential } from "firebase/auth";
import type { LoginCredentials, LoginEmailLinkCredentials, UpdatePasswordCredentials } from "./type";

import axios from "axios";
import { getRedirectResult } from "firebase/auth";

import { firebaseAuth, firebaseUtils } from "@/libs/firebaseAuth";

export const getAuthUser = () => {
    return firebaseAuth.currentUser;
};

export const getGoogleRedirectResult = async () => {
    return await getRedirectResult(firebaseAuth);
};

export const setAccessToken = async (force = false) => {
    const accessToken = await firebaseAuth.currentUser?.getIdToken(force);
    if (accessToken && firebaseAuth.currentUser?.emailVerified) {
        /** Reconfigure axios with access token. */
        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    }
};

export const SignUpWithEmailAndPassword = async ({ email, password }: LoginCredentials): Promise<UserCredential> => {
    return firebaseUtils.createUserWithEmailAndPassword(firebaseAuth, email, password);
};

export const logInWithEmailAndPassword = async ({
    email,
    password,
    rememberMe = true,
}: LoginCredentials): Promise<UserCredential> => {
    await firebaseAuth.setPersistence(
        rememberMe ? firebaseUtils.browserLocalPersistence : firebaseUtils.browserSessionPersistence
    );

    return firebaseUtils.signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const logInWithEmailLink = async ({ email, emailLink }: LoginEmailLinkCredentials): Promise<UserCredential> => {
    if (!firebaseUtils.isSignInWithEmailLink(firebaseAuth, emailLink)) {
        throw new Error("Invalid email link");
    }

    await firebaseAuth.setPersistence(firebaseUtils.browserLocalPersistence);

    return firebaseUtils.signInWithEmailLink(firebaseAuth, email, emailLink);
};

export const logout = () => firebaseAuth.signOut();
export const updatePassword = async ({ password, oldPassword }: UpdatePasswordCredentials): Promise<boolean> => {
    try {
        if (!firebaseAuth?.currentUser || !firebaseAuth?.currentUser?.email) {
            return false;
        }
        const cred = firebaseUtils.EmailAuthProvider.credential(firebaseAuth?.currentUser?.email, oldPassword);
        await firebaseUtils.reauthenticateWithCredential(firebaseAuth?.currentUser ?? null, cred);
        await firebaseUtils.updatePassword(firebaseAuth?.currentUser, password);
        return true;
    } catch (error) {
        return false;
    }
};
