import type { ReactNode, RefAttributes } from "react";
import type { InheritableElementProps } from "@/types";

import { createPortal } from "react-dom";

import { tx } from "@/libs/tailwindMerge";

export type AprPortalProps = {
    container?: HTMLElement | null;
    children: ReactNode;
};

export const AprPortal = ({ container, children }: AprPortalProps) => {
    return createPortal(children, container ?? document.body);
};

export const AprOverlay = ({
    className,
    children,
    ...props
}: InheritableElementProps<"div"> & RefAttributes<HTMLDivElement>) => {
    return (
        <AprPortal>
            <div
                className={tx(
                    "fixed inset-0 overflow-hidden",
                    "transition-all duration-300 motion-reduce:transition-none",
                    "before:fixed before:inset-0 before:-z-10 before:bg-none before:backdrop-blur-lg",
                    className
                )}
                {...props}
            >
                {children}
            </div>
        </AprPortal>
    );
};
