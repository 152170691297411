import type {
    DemoUserAccount,
    PostAccountOperation,
    PostDemoUserTradingPreferences,
    PostDemoUserCreateMT4Account,
    PostDemoUserCreateTeAccount,
    PutChangeLeverage,
    GetDemoAccountsParams,
    PutChangeLiveLeverage,
} from "./type";
import type { TradingAccount, TradingAccountType } from "../type";

import { axios } from "@/libs/axios";

export const getDemoAccounts = async (params: GetDemoAccountsParams) => {
    let { data: demoAccounts = [] } = await axios.get<DemoUserAccount[]>("/accounts/demo", {
        params: { ...params, isKycPage: true, AccountType: "Both" },
    });
    demoAccounts = demoAccounts.map(
        (acc) =>
            ({
                ...acc,
                status: acc.status || "Active",
                role: "Demo",
                loading: true,
            } as TradingAccount)
    );

    /**
     * Create a hashmap by type to speed up data handling by account type.
     */
    const demoAccountsByType = demoAccounts.reduce<Record<TradingAccountType, TradingAccount[]>>(
        (list, account) => {
            if (list[account.type]) {
                list[account.type].push(account);
            } else {
                list[account.type] = [account];
            }

            return list;
        },
        { Te: [], Mt4: [] }
    );
    return { demoAccounts, demoAccountsByType };
};

export const getTeDemoAccounts = async (params: GetDemoAccountsParams) => {
    let { data: demoTeAccounts = [] } = await axios.get<DemoUserAccount[]>("/accounts/demo", {
        params: { ...params, AccountType: "te" },
    });
    demoTeAccounts = demoTeAccounts.map(
        (acc) =>
            ({
                ...acc,
                status: acc.status || "Active",
                role: "Demo",
                loading: false,
            } as TradingAccount)
    );
    return { demoTeAccounts };
};
export const getMt4DemoAccounts = async (params: GetDemoAccountsParams) => {
    let { data: demoMt4Accounts = [] } = await axios.get<DemoUserAccount[]>("/accounts/demo", {
        params: { ...params, AccountType: "mt4" },
    });
    demoMt4Accounts = demoMt4Accounts.map(
        (acc) =>
            ({
                ...acc,
                status: acc.status || "Active",
                role: "Demo",
                loading: false,
            } as TradingAccount)
    );

    return { demoMt4Accounts };
};

export const postCreateDemoTeAccount = async (payload: PostDemoUserCreateTeAccount) => {
    const { data } = await axios.post<Pick<TradingAccount, "accountId" | "accountName">>("/accounts/demo/te", payload);
    return data;
};

export const postCreateDemoAccountTradingPreferences = async (data: PostDemoUserTradingPreferences) => {
    await axios.post("/accounts/demo", { ...data, accountCreationType: 1 });
};

export const postCreateDemoMt4Account = async (payload: PostDemoUserCreateMT4Account) => {
    const { data } = await axios.post<Pick<TradingAccount, "accountId" | "accountName">>("/accounts/demo/mt4", payload);
    return data;
};

export const putChangeLeverage = async (data: PutChangeLeverage) => {
    await axios.put("/accounts/demo/leverage", data);
};

export const putChangeLiveLeverage = async (data: PutChangeLiveLeverage) => {
    await axios.put("accounts/live/accounts-leverage", data);
};

export const postAccountOperation = async (data: PostAccountOperation) => {
    await axios.post("/accounts/demo/operation", data);
};
