import type { AxiosError } from "axios";
import type { TradingAccount, TradingAccountType } from "@/query/type";
import type { DemoUserTradingPreferences } from "./type";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useCurrentUser } from "@/query/users";

import {
    getDemoAccounts,
    postCreateDemoAccountTradingPreferences,
    postCreateDemoMt4Account,
    postCreateDemoTeAccount,
    postAccountOperation,
    putChangeLeverage,
    putChangeLiveLeverage,
    getMt4DemoAccounts,
    getTeDemoAccounts,
} from "./api";

/**
 * Demo trading account queries.
 */
export const useDemoAccounts = (config?: {
    /**
     * If `true`, BE will forego balance checks. Useful for quick fetch of the accounts on KYC pages, where only the
     * account infos matter.
     */
    isKycPage?: boolean;
}) => {
    const { user, isLoading: isUserLoading } = useCurrentUser();

    const {
        data = {
            demoAccounts: [],
            demoAccountsByType: { Te: [], Mt4: [] },
        },
        isLoading,
        ...props
    } = useQuery(["demo", "accounts"], {
        refetchOnMount: true,
        enabled: !!user && (user.role === "Demo" || (user.role === "Live" && user.isPiProvided)),
        /** Auto-refetch if & only if the user is on the demo-rego flow & awaiting account creation. */
        refetchInterval: (data) => (data?.demoAccounts.length === 0 ? 10 * 1000 : false),
        queryFn: () => getDemoAccounts({ isKycPage: config?.isKycPage }),
    });

    return { ...data, isLoading: isLoading || isUserLoading, ...props };
};
export const useTeDemoAccounts = (config?: {
    /**
     * If `true`, BE will forego balance checks. Useful for quick fetch of the accounts on KYC pages, where only the
     * account infos matter.
     */
    isKycPage?: boolean;
}) => {
    const { user, isLoading: isUserLoading } = useCurrentUser();

    const {
        data = { demoTeAccounts: [] },
        isLoading,
        ...props
    } = useQuery(["demo", "accounts", "te"], {
        refetchOnMount: true,
        enabled: !!user && (user.role === "Demo" || (user.role === "Live" && user.isPiProvided)),
        /** Auto-refetch if & only if the user is on the demo-rego flow & awaiting account creation. */
        refetchInterval: (data) => (data?.demoTeAccounts.length === 0 ? 3 * 1000 : false),
        queryFn: () => getTeDemoAccounts({ isKycPage: config?.isKycPage }),
    });

    return { ...data, isLoading: isLoading || isUserLoading, ...props };
};

export const useMt4DemoAccounts = (config?: {
    /**
     * If `true`, BE will forego balance checks. Useful for quick fetch of the accounts on KYC pages, where only the
     * account infos matter.
     */
    isKycPage?: boolean;
}) => {
    const { user, isLoading: isUserLoading } = useCurrentUser();

    const {
        data = { demoMt4Accounts: [] },
        isLoading,
        ...props
    } = useQuery(["demo", "accounts", "mt4"], {
        refetchOnMount: true,
        enabled: !!user && (user.role === "Demo" || (user.role === "Live" && user.isPiProvided)),
        /** Auto-refetch if & only if the user is on the demo-rego flow & awaiting account creation. */
        refetchInterval: (data) => (data?.demoMt4Accounts.length === 0 ? 3 * 1000 : false),
        queryFn: () => getMt4DemoAccounts({ isKycPage: config?.isKycPage }),
    });

    return { ...data, isLoading: isLoading || isUserLoading, ...props };
};
/**
 * Demo trading account mutations.
 */
export const useCreateDemoUserTradingPreferences = () => {
    const queryClient = useQueryClient();

    const { mutate: createDemoAccountTradingPreferences, ...props } = useMutation({
        mutationFn: postCreateDemoAccountTradingPreferences,
        onSuccess: () => queryClient.refetchQueries(["demo", "accounts"]),
    });

    return { createDemoAccountTradingPreferences, ...props };
};

export const useCreateDemoTeAccount = () => {
    const queryClient = useQueryClient();

    const { mutate: createDemoTeAccount, ...props } = useMutation<
        Pick<TradingAccount, "accountId" | "accountName">,
        AxiosError,
        DemoUserTradingPreferences
    >({
        mutationFn: postCreateDemoTeAccount,
        onSuccess: () => queryClient.refetchQueries(["demo", "accounts", "te"]),
    });

    return { createDemoTeAccount, ...props };
};

export const useCreateDemoMt4Account = () => {
    const queryClient = useQueryClient();

    const { mutate: createDemoMt4Account, ...props } = useMutation<
        Pick<TradingAccount, "accountId" | "accountName">,
        AxiosError,
        DemoUserTradingPreferences
    >({
        mutationFn: postCreateDemoMt4Account,
        onSuccess: () => queryClient.refetchQueries(["demo", "accounts", "mt4"]),
    });

    return { createDemoMt4Account, ...props };
};

export const useAccountOperation = (type: TradingAccountType) => {
    const queryClient = useQueryClient();

    const { mutate: accountOperation, ...props } = useMutation({
        mutationFn: postAccountOperation,
        onSuccess: () => {
            if (type === "Te") {
                queryClient.refetchQueries(["demo", "accounts", "te"]);
            } else {
                queryClient.refetchQueries(["demo", "accounts", "mt4"]);
            }
        },
    });

    return { accountOperation, ...props };
};

export const useChangeLeverage = (type: TradingAccountType) => {
    const queryClient = useQueryClient();

    const { mutate: changeLeverage, ...props } = useMutation({
        mutationFn: putChangeLeverage,
        onSuccess: () => {
            if (type === "Te") {
                queryClient.refetchQueries(["demo", "accounts", "te"]);
            } else {
                queryClient.refetchQueries(["demo", "accounts", "mt4"]);
            }
        },
    });

    return { changeLeverage, ...props };
};

export const useChangeLiveLeverage = (type: TradingAccountType) => {
    const queryClient = useQueryClient();

    const { mutate: changeLiveLeverage, ...props } = useMutation({
        mutationFn: putChangeLiveLeverage,
        onSuccess: () => {
            if (type === "Te") {
                queryClient.refetchQueries(["live", "accounts", "te"]);
            } else {
                queryClient.refetchQueries(["live", "accounts", "mt4"]);
            }
        },
    });
    return { changeLiveLeverage, ...props };
};
