import type { PropsWithChildren } from "react";

import { useState, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const RecaptchaProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
    const [enableRecaptcha, setEnableRecaptcha] = useState(false);

    useEffect(() => {
        setEnableRecaptcha(import.meta.env.VITE_NODE_ENV === "production");
    }, []);

    return enableRecaptcha ? (
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}>{children}</GoogleReCaptchaProvider>
    ) : (
        <>{children}</>
    );
};
