import { useQuery } from "react-query";

import { isEU } from "@/libs/processEnv";

import { useCurrentUser } from "@/query/users";

import { getAllCountries, getAllTINCountries } from "./api";
export const useAllCountries = () => {
    const { user } = useCurrentUser();

    const {
        data: { allCountries, bannedCountries, userCountry } = { allCountries: [], bannedCountries: [] },
        ...props
    } = useQuery(["countries", user?.country], {
        queryFn: async () => {
            const allCountries = (await getAllCountries()) ?? [];
            const bannedCountries = allCountries.filter(({ isBanned }) => isBanned);

            /**
             * ***CAVEAT***: This ideally could live in the /user query, but find() is needlessly expensive for one of the
             * most common query in the whole application.
             *
             * Considering moving this to /user if using useAllCountries() & useCurrentUser() concurrently becomes
             * too cumbersome.
             */
            const userCountry = user?.country ? allCountries.find(({ id }) => id === user?.country) : undefined;
            return {
                allCountries,
                bannedCountries,
                userCountry,
            };
        },
    });

    return { allCountries, bannedCountries, userCountry, ...props };
};

export const useTINCountries = () => {
    const { data: tinCountries = [], ...props } = useQuery(["tinCountries"], {
        enabled: isEU(),
        queryFn: async () => {
            const tinCountries = (await getAllTINCountries()) ?? [];
            return tinCountries;
        },
    });

    return { tinCountries, ...props };
};
