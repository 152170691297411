import axios from "axios";
import qs from "qs";
import ls from "localstorage-slim";

import { firebaseAuth } from "@/libs/firebaseAuth";
import { queryClient } from "@/libs/reactQuery";

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
    paramsSerializer: {
        serialize: (params) => {
            return qs.stringify(params);
        },
    },
});

axiosInstance.interceptors.response.use(async (response) => {
    // Make sure that it's not a false-positive 401 (ie: during initialization)
    if (response.status === 401 && response.config.headers.Authorization) {
        await firebaseAuth.signOut();

        ls.clear();
        queryClient.clear();
        axios.defaults.headers.Authorization = null;
    }

    return response;
});

export { axiosInstance as axios };
