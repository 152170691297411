import type { Merge } from "@/types";
import type { AprCardProps } from "@/components/Card";
import type { AprButtonProps } from "@/components/Button";

import { useCallback, createContext, useContext, useState, useEffect } from "react";

import { ex } from "@/libs/eventMerge";
import { tx } from "@/libs/tailwindMerge";

import { AprCard } from "@/components/Card";
import { AprButton } from "@/components/Button";

export type AprTabProps = Merge<
    AprCardProps,
    {
        value?: string;
        onTabChange?: (value: string) => void;
    }
>;

export type AprTabItemProps = Merge<
    AprButtonProps,
    {
        value: string;
    }
>;

const AprTabContext = createContext<{
    activeValue?: string;
    handleItemClick?: (value: string) => void;
}>({});

const AprTabItem = ({ disabled = false, value, className, onClick, ...props }: AprTabItemProps) => {
    const { activeValue, handleItemClick } = useContext(AprTabContext);

    return (
        <AprButton
            type="button"
            size="small"
            disabled={disabled}
            aria-selected={activeValue === value ? "true" : undefined}
            variant={activeValue === value ? "primary" : "ghost"}
            className={tx(
                "px-3",
                {
                    "border-transparent bg-background text-foreground hover:border-transparent hover:bg-background":
                        disabled,
                },
                className
            )}
            onClick={ex(onClick, () => handleItemClick?.(value))}
            {...props}
        />
    );
};

export const AprTab = ({ value, className, children, onTabChange, ...props }: AprTabProps) => {
    const [activeValue, setActiveValue] = useState(value);

    useEffect(() => setActiveValue(value), [value]);

    const handleItemClick = useCallback(
        (value: string) => {
            onTabChange?.(value);
            setActiveValue(value);
        },
        [onTabChange]
    );

    return (
        <AprCard role="switch" className={tx("inline-flex rounded-full p-0", className)} {...props}>
            <AprTabContext.Provider value={{ activeValue, handleItemClick }}>{children}</AprTabContext.Provider>
        </AprCard>
    );
};

AprTab.Item = AprTabItem;
