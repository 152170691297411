import { QueryCache, QueryClient } from "react-query";

export const queryClient = new QueryClient({
    queryCache: new QueryCache({}),
    defaultOptions: {
        queries: {
            /**
             * Reduce the amount of retries for now since BE is flaky.
             */
            retry: 3,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: true,
            notifyOnChangeProps: "tracked",
            staleTime: 1000 * 5 * 60, // 5 mins
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
});
