import type { PraxisGateway } from "@/query/deposit";
import type { TradingAccount, TradingAccountType, TradingCurrency } from "@/query/type";

import { TradingCurrencySymbol } from "@/query/type";

export const currencySymbolFormatter = (currency: keyof typeof TradingCurrency | string): string =>
    TradingCurrencySymbol[currency as keyof typeof TradingCurrency] ?? "";

export const balanceFormatter = (balance?: number, currency?: keyof typeof TradingCurrency | string): string => {
    if (balance === undefined || balance === null) {
        return "--";
    }

    let decimalCount = 2;
    if (currency === "JPY") {
        decimalCount = 0;
    }

    return Number(balance || 0)
        .toFixed(decimalCount)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const accountTypeFormatter = (type?: TradingAccountType, options?: { acronym?: boolean }): string =>
    type ? (type === "Te" ? (options?.acronym ? "TE" : "TradingView / TraderEvolution") : "MT4") : "--";

export const accountLabelFormatter = ({ accountId, accountName, currency, balance, type }: TradingAccount): string =>
    accountId
        ? `${accountName} ${currency} ${currencySymbolFormatter(currency)}${balanceFormatter(
              balance || 0,
              currency
          )} (${accountTypeFormatter(type)})`
        : "--";
export const depositLabelFormatter = ({ display_name, payment_method }: PraxisGateway) =>
    display_name ? `${display_name} (${payment_method})` : "--";

export const nonNullishObjectFormatter = <T = unknown>(obj: Record<string, T>) =>
    Object.fromEntries(Object.entries(obj).filter(([_k, v]) => !!v));

export const capitalizeFormatter = (name?: string): string =>
    (name || "")
        .split(" ")
        .map((s) => s.charAt(0).toLocaleUpperCase() + s.slice(1))
        .join(" ");
