export const Maintenance = () => {
    return (
        <div className="flex h-screen items-center justify-center">
            <div>
                <div className="wrap">
                    <div className="flex justify-center">
                        <img src="/images/title-logo-dark.svg" alt="logo" className="dark:hidden" />
                        <img src="/images/title-logo-light.svg" alt="logo" className="hidden dark:block" />
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="dark: text-center text-4xl font-semibold text-themeColor">
                        We&rsquo;ll be back soon!
                    </div>
                    <br />
                    <br />
                    <br />
                    <div>
                        <p className="text-center text-xl">
                            Sorry for the inconvenience.
                            <br />
                            Our website is currently undergoing scheduled maintenance.
                            <br />
                            <br />
                        </p>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};
