import type { ReactNode } from "react";
import type { InheritableElementProps } from "@/types";

import React, { useLayoutEffect, useState } from "react";

import { usePrevious } from "@/hooks/usePrevious";

export type ThemeProps = InheritableElementProps<
    "div",
    {
        children: ReactNode;
    }
>;

type ContextType = {
    theme: "light" | "dark";
    toggleTheme: () => void;
};

export const ThemeContext = React.createContext<ContextType>({
    theme: "dark",
    toggleTheme: () => {},
});

export const ThemeProvider = ({ children }: ThemeProps) => {
    const [theme, setTheme] = useState<"light" | "dark">(
        (localStorage.getItem("ui.theme") as "light" | "dark") || "dark"
    );
    // update root element class on theme change
    const oldTheme = usePrevious(theme);
    useLayoutEffect(() => {
        document.documentElement.classList.remove(`${oldTheme}`);
        document.documentElement.classList.add(`${theme}`);
    }, [theme, oldTheme]);

    const toggleTheme = (): void => {
        const val = theme === "light" ? "dark" : "light";
        setTheme(val);
        localStorage.setItem("ui.theme", val);
    };

    return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
