import { Navigate, Outlet, useLocation } from "react-router-dom";

type NonMatchRedirectProps = {
    /** If `true`, path matching will resolve if matches partially (aka. match w/ child routes). */
    relative?: boolean;
    /** List of routes allowed in the current state. */
    to: string | string[];
};

/**
 * Redirect wrapper that avoids infinite rerouting on protected routes.
 */
export const NonMatchRedirect = ({ relative = false, to }: NonMatchRedirectProps) => {
    const { pathname, search } = useLocation();
    const fullPath = `${pathname}${search}`;

    /** Setup a list of valid paths. */
    const toArray = Array.isArray(to) ? to : [to];

    return toArray.find((path) => (relative ? fullPath.includes(path) : path === fullPath)) ? (
        <Outlet />
    ) : (
        <Navigate to={toArray[0] ?? "/"} />
    );
};
