/**
 * Utilities to check environment.
 */
export const isDev = () => {
    return import.meta.env.VITE_NODE_ENV === "development";
};

export const isProd = () => {
    return import.meta.env.VITE_NODE_ENV === "prod" || import.meta.env.VITE_NODE_ENV === "production";
};

export const isMaintenanceMode = () => {
    return import.meta.env.VITE_MAINTENANCE_MODE === "true" || false;
};

export const isEU = () => {
    return import.meta.env.VITE_REGION === "EU";
};
export const europeSiteUrl = "https://afterprime.eu";
export const globalSiteUrl = "https://afterprime.com";
export const europeAppUrl = "https://app.afterprime.eu";
export const globalAppeUrl = "https://app.afterprime.com";
export const officialSite = import.meta.env.VITE_REGION === "EU" ? europeSiteUrl : globalSiteUrl;
export const officialAppUrl = import.meta.env.VITE_REGION === "EU" ? europeAppUrl : globalAppeUrl;
export const isSameRegion = (jurisdiction: string | undefined) => {
    if (!isEU()) {
        return {
            eu: false,
            show: false,
        };
    } else if (isEU()) {
        return {
            eu: jurisdiction === "CySEC",
            show: true,
        };
    } else {
        return {
            eu: false,
            show: false,
        };
    }
};
