import { lazily } from "react-lazily";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { isEU } from "@/libs/processEnv";

import { LoadingLayout } from "@/features/loadingLayout";

import { AutoRedirectRoute } from "./AutoRedirectRoute";
import { ProtectedRoute } from "./ProtectedRoute";

const { LoginPage, LoadingPage } = lazily(() => import("@/features/login"));
const {
    VerifyEmailPage,
    VerifiedEmailPage,
    TradingOptionsPage,
    EUTradingOptionsPage,
    WelcomePage,
    AcceptVerificationPage,
    VerifyIdentityPage,
    LiveSuccessPage,
    LiveRegisterPage,
    EULiveRegisterPage,
    AssessmentPage,
    TradingExperiencePage,
    DemoAccountsPage,
    DemoToLiveOptionPage,
    EuSpainPage,
} = lazily(() => import("@/features/liveRego"));
const { DemoRegisterPage, DemoVerifyEmailPage, DemoVerifiedEmailPage, DemoTradingOptionsPage, DemoSuccessPage } =
    lazily(() => import("@/features/demoRego"));
const { ForgotPasswordPage, VerifyForgotEmailPage, ChangePasswordPage, InvitePage } = lazily(
    () => import("@/features/resetPassword")
);

const { DashboardMainPage } = lazily(() => import("@/features/dashboard"));
const { GoLivePage } = lazily(() => import("@/features/demoToLive"));
const { AssessmentFailedPage } = lazily(() => import("@/features/assessment"));
const { DashboardKYCSuccessPage, DashboardNoDepositPage, DashboardNoKYCPage } = lazily(() => import("@/features/kyc"));
const { DepositPage, DepositBankTransferPage, DepositInstructionPage, DepositConfirmationPage } = lazily(
    () => import("@/features/deposit")
);
const { ReferAFriendPage } = lazily(() => import("@/features/referAFriend"));
const { DepositPage: DepositNewFlowPage } = lazily(() => import("@/features/depositNewFlow"));
const { WalletPage } = lazily(() => import("@/features/wallet"));
const { TradesPage, TradeDetailsPage } = lazily(() => import("@/features/trades"));
const { WithDrawal } = lazily(() => import("@/features/withdrawal"));
const { ManageBankingPage } = lazily(() => import("@/features/manageBanking"));
const { AccountStatementPage } = lazily(() => import("@/features/accountStatement"));
const { LoginViaOtpPage } = lazily(() => import("@/features/loginViaOtp"));
const { PasswordChangePage } = lazily(() => import("@/features/passwordChange"));
const { ElectiveProfessionalMainPage } = lazily(() => import("@/features/electiveProfessional"));
const { DisabledAccount } = lazily(() => import("@/components/DisabledAccount"));
const { OtpSettingsPage } = lazily(() => import("@/features/otpSettings"));

export const NewRoutes = () => {
    return (
        <Suspense fallback={<LoadingLayout />}>
            <Routes>
                <Route index path="/" element={<Navigate replace to="/login" />} />
                <Route path="*" element={<Navigate replace to="/" />} />

                <Route path="verified-email" element={<VerifiedEmailPage />} />
                <Route path="demo/verified-email" element={<DemoVerifiedEmailPage />} />
                <Route path="change-password" element={<ChangePasswordPage />} />

                <Route path="/" element={<AutoRedirectRoute />}>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="loading" element={<LoadingPage />} />

                    <Route path="forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="verify-forgot-email" element={<VerifyForgotEmailPage />} />
                    <Route path="password-changed" element={<LoginPage />} />

                    <Route path="live" element={isEU() ? <EULiveRegisterPage /> : <LiveRegisterPage />} />
                    {!isEU() && (
                        <Route path="raf/:referCode" element={isEU() ? <EULiveRegisterPage /> : <LiveRegisterPage />} />
                    )}
                    <Route path="verify-email" element={<VerifyEmailPage />} />

                    <Route path="demo" element={<DemoRegisterPage />} />
                    <Route path="demo/verify-email" element={<DemoVerifyEmailPage />} />

                    <Route path="invite" element={<InvitePage />} />
                </Route>
                <Route path="/" element={<ProtectedRoute />}>
                    <Route path="disabled" element={<DisabledAccount />} />
                    {/** Dashboard */}
                    <Route index path="verify-otp" element={<LoginViaOtpPage />} />
                    <Route index path="otp-settings" element={<OtpSettingsPage />} />
                    <Route index path="dashboard" element={<DashboardMainPage />} />
                    <Route path="dashboard/no-kyc" element={<DashboardNoKYCPage />} />
                    <Route path="dashboard/kyc-success" element={<DashboardKYCSuccessPage />} />
                    <Route path="dashboard/no-deposit" element={<DashboardNoDepositPage />} />
                    <Route path="dashboard/go-live" element={<GoLivePage />} />
                    {isEU() && <Route path="dashboard/assessment-failed" element={<AssessmentFailedPage />} />}
                    {/** Core features */}
                    <Route path="manage-banking" element={<ManageBankingPage />} />
                    <Route path="account-statement" element={<AccountStatementPage />} />
                    <Route path="refer-a-friend" element={<ReferAFriendPage />} />
                    <Route path="legacy-deposit" element={<DepositPage />} />
                    <Route path="deposit" element={<DepositNewFlowPage />} />
                    <Route path="wallet" element={<WalletPage />} />
                    <Route path="deposit/bank-transfer" element={<DepositBankTransferPage />} />
                    <Route path="deposit/transfer-instruction/:currency" element={<DepositInstructionPage />} />
                    <Route path="deposit-confirmation/:transactionId" element={<DepositConfirmationPage />} />
                    <Route path="withdraw" element={<WithDrawal />} />
                    <Route path="trades" element={<TradesPage />} />
                    <Route path="trades/:tradeId" element={<TradeDetailsPage />} />
                    <Route path="password-change" element={<PasswordChangePage />} />
                    {isEU() && <Route path="elective-professional" element={<ElectiveProfessionalMainPage />} />}

                    {/** Live registration */}
                    <Route
                        path="trading-options"
                        element={isEU() ? <EUTradingOptionsPage /> : <TradingOptionsPage />}
                    />
                    {isEU() && <Route path="convert-to-live" element={<DemoToLiveOptionPage />} />}
                    {isEU() && (
                        <>
                            <Route path="assessment" element={<AssessmentPage />} />
                            <Route path="trading-experience" element={<TradingExperiencePage />} />
                        </>
                    )}
                    <Route path="welcome" element={<WelcomePage />} />
                    {isEU() && <Route path="verify-europe" element={<EuSpainPage />} />}
                    <Route path="accept-verification" element={<AcceptVerificationPage />} />
                    <Route path="verify-identity" element={<VerifyIdentityPage />} />
                    <Route path="live-success" element={<LiveSuccessPage />} />
                    <Route path="demo-success" element={<DemoAccountsPage />} />

                    {/** Demo registration */}
                    <Route path="demo/trading-options" element={<DemoTradingOptionsPage />} />
                    <Route path="demo/demo-success" element={<DemoSuccessPage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};
