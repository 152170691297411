import type { Currency } from "./currencies/type";

import { invertKeyValues } from "@/libs/invert";
import { isEU } from "@/libs/processEnv";

/** Common types & useful constants/enums. */

export const PAGINATION_DEFAULT_SIZE = 25;

export const EUTradingCurrency = {
    USD: 1,
    EUR: 5,
    GBP: 6,
};
export const TradingCurrency = {
    ...EUTradingCurrency,
    SGD: 17,
    JPY: 7,
    CAD: 3,
    AUD: 2,
};

export const TradingCurrencyInverted = invertKeyValues<string, number>(isEU() ? EUTradingCurrency : TradingCurrency);

export const TradingCurrencyList: Currency[] = Object.entries(isEU() ? EUTradingCurrency : TradingCurrency).map(
    ([name, id]) => ({
        id,
        name: name as keyof typeof TradingCurrency,
    })
);

export const TradingCurrencySymbol: Record<keyof typeof TradingCurrency, string> = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    SGD: "S$",
    JPY: "¥",
    CAD: "CA$",
    AUD: "A$",
};

export const TradingCurrencyName: Record<keyof typeof TradingCurrency, string> = {
    USD: "US Dollars",
    EUR: "Euro",
    GBP: "Pound Sterling",
    SGD: "Singapore Dollars",
    JPY: "Japanese Yen",
    CAD: "Canadian Dollars",
    AUD: "Australian Dollars",
};

export const LiveTradingLeverageWithLegacy = {
    "1:1": 25000,
    "1:5": 13838,
    "1:10": 13837,
    "1:25": 13836,
    "1:33": 13403,
    "1:100": 9979,
    "1:200": 10378,
    "1:400": 21870, // dummy ID as not such leverage in TE
};

export const LiveTradingLeverage = {
    "1:1": 25000,
    "1:5": 13838,
    "1:10": 13837,
    "1:25": 13836,
    "1:33": 13403,
    "1:100": 9979,
    "1:200": 10378,
    "1:400": 21870,
};

export const DemoTradingLeverage = {
    "1:1": 25001,
    "1:5": 12783,
    "1:10": 12782,
    "1:25": 12781,
    "1:33": 12780,
    "1:100": 10479,
    "1:200": 10869,
    "1:400": 39443,
};

export const DefaultCurrencyData = {
    currencyList: TradingCurrencyList,
    currencies: (isEU() ? EUTradingCurrency : TradingCurrency) as Record<string, number>,
    currencyInverted: TradingCurrencyInverted,
    currencySymbols: TradingCurrencySymbol,
    currencyNames: TradingCurrencyName,
};

export const LiveTradingLeverageInverted = invertKeyValues<string, number>(LiveTradingLeverage);
export const DemoTradingLeverageInverted = invertKeyValues<string, number>(DemoTradingLeverage);

export type TradingAccountType = "Te" | "Mt4";

export type TradingAccountRole = "Live" | "Demo";

export type TradingAccount = {
    accountId: string;
    accountName: string;
    balance: number;
    currency: keyof typeof EUTradingCurrency | keyof typeof TradingCurrency;
    leverage: keyof typeof DemoTradingLeverage | keyof typeof LiveTradingLeverageWithLegacy | undefined;
    login?: string;
    masterPassword?: string;
    nickName?: string;
    type: TradingAccountType;
    status: "Active" | "NotFunded" | "Archived";
    role: TradingAccountRole;
    loading?: boolean;
    isLeverageFourHundred?: boolean;
    setDisableClose: () => void;
    setFalseDisable: () => void;
};

export const OTP_VERIFIED = "verified-otp";
export const ENABLE_USER = "enable-user";
