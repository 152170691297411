import "./styles/globals.css";

import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";

import { isDev, isMaintenanceMode } from "@/libs/processEnv";
import { RecaptchaProvider } from "@/libs/recaptcha";
import { queryClient } from "@/libs/reactQuery";

import { getAuthUser } from "@/query/auth/api";

import { AprVersionOverlay } from "@/features/version";
import { AprToastMaster } from "@/features/toastMaster";

import { NewRoutes } from "@/routes";

import { ThemeProvider } from "@/components/ThemeContext";
import { Maintenance } from "@/components/Maintenance";

export const App = () => {
    useEffect(() => {
        window.loadPraxisHpfScript();
        /** Prefetch auth state for slightly faster/stable loading. */
        queryClient.prefetchQuery(["auth"], {
            retry: 3,
            queryFn: getAuthUser,
        });
    }, []);

    if (isMaintenanceMode()) return <Maintenance />;
    else
        return (
            <ThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <RecaptchaProvider>
                                <NewRoutes />
                            </RecaptchaProvider>
                        </QueryParamProvider>
                    </BrowserRouter>
                    {isDev() && (
                        <>
                            <AprToastMaster />
                            <AprVersionOverlay />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </>
                    )}
                </QueryClientProvider>
            </ThemeProvider>
        );
};
