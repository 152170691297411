import ls from "localstorage-slim";
import { Outlet } from "react-router-dom";

import { isEU } from "@/libs/processEnv";

import { useDevInfo } from "@/query/dev";
import { useUserAuth } from "@/query/auth";
import { useDemoAccounts } from "@/query/demo";
import { useCurrentUser } from "@/query/users";
import { useAllCountries } from "@/query/countries";
import { OTP_VERIFIED } from "@/query/type";

import { LoadingLayout } from "@/features/loadingLayout";

import { NonMatchRedirect } from "./NonMatchRedirect";
import { DASHBOARD_ROUTES, DEPOSIT_ROUTES, KYC_ROUTES } from "./constants";

/**
 * Protected route that auto-redirect if certain user-related conditions aren't met.
 */
export const ProtectedRoute = () => {
    const {
        devInfo: { authMode },
    } = useDevInfo();

    const { auth, isLoading: isAuthLoading } = useUserAuth();
    const { user, isLoading: isUserLoading, isError } = useCurrentUser();
    const { demoAccounts, isLoading: isDemoAccountsLoading } = useDemoAccounts();
    const { userCountry, isLoading: isCountryLoading } = useAllCountries();

    /**
     * Basically, bypassing all auth/user checks.
     */
    if (authMode === "skip") {
        return <Outlet />;
    }

    /** Error & loading state handling. */
    if (isUserLoading || isAuthLoading || (isEU() && isCountryLoading)) {
        return <LoadingLayout />;
    }

    if (auth && !auth?.emailVerified) {
        return <NonMatchRedirect to={`/verify-email?email=${auth?.email}`} />;
    }

    if (auth === null || isError) {
        return <NonMatchRedirect to="/" />;
    }

    if (user && user.otpEnabled && !ls.get(OTP_VERIFIED)) {
        return <NonMatchRedirect to="/verify-otp" />;
    }

    /** Demo-related routings. */
    if (user && user.role === "Demo") {
        if (isDemoAccountsLoading) {
            return <LoadingLayout />;
        }

        if (demoAccounts.length === 0) {
            return <NonMatchRedirect to={["/demo/demo-success", "/password-change"]} />;
        }

        return (
            <NonMatchRedirect
                to={[
                    "/dashboard",
                    "/dashboard/go-live",
                    "/demo/demo-success",
                    "/trading-options",
                    "/convert-to-live",
                    "/welcome",
                    "/deposit",
                    ...DASHBOARD_ROUTES,
                ]}
            />
        );
    }

    /** Live-related routings. */
    if (user && user.role === "Live") {
        if (!user.isPiProvided) {
            return <NonMatchRedirect to="/trading-options" />;
        }

        if (isEU() && !user.isAssessmentPassed && !user.isAssessmentCompleted) {
            return <NonMatchRedirect to={["/assessment", "/trading-experience", "/welcome"]} />;
        }

        if (!user.isEulaAccepted) {
            return <NonMatchRedirect to="/welcome" />;
        }

        if (!isEU() && !user.isKycCompleted) {
            return <NonMatchRedirect to={[...KYC_ROUTES, "/welcome", "/deposit", ...DASHBOARD_ROUTES]} />;
        }

        if (isEU() && !user.isAssessmentPassed) {
            return <NonMatchRedirect relative to={["/demo-success", "/deposit", ...DASHBOARD_ROUTES]} />;
        }

        if (
            isEU() &&
            user.isAssessmentPassed &&
            userCountry?.name === "Spain" &&
            user?.clientAcknowledgement === null &&
            !user.isKycCompleted
        ) {
            return <NonMatchRedirect to={["/verify-europe", ...KYC_ROUTES, "/deposit", ...DASHBOARD_ROUTES]} />;
        }
        if (isEU() && user.isAssessmentPassed && !user.isKycCompleted) {
            return <NonMatchRedirect to={[...KYC_ROUTES, "/deposit", ...DASHBOARD_ROUTES]} />;
        }

        /** Explicitly only allow users to traverse dashboard pages once all rego flow is completed. */
        return (
            <NonMatchRedirect
                relative
                to={[
                    ...DASHBOARD_ROUTES.filter((r) => (isEU() ? true : r !== "/elective-professional")),
                    ...DEPOSIT_ROUTES,
                    "/live-success",
                    "/dashboard/kyc-success",
                ]}
            />
        );
    }

    return <Outlet />;
};
