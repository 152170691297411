/**
 * Expand this list if we need.
 * Routes that requires specific layouts.
 */
export const PASSWORD_CHANGE_ROUTE = "/password-change";
export const WALLET_ROUTE = "/wallet";
export const DASHBOARD_ROUTE = "/dashboard";
export const KYC_ROUTES = [
    "/accept-verification",
    "/dashboard/no-kyc",
    "/dashboard/kyc-success",
    "/verify-identity",
    "/live-success",
    PASSWORD_CHANGE_ROUTE,
];

export const DEPOSIT_ROUTES = [
    "/dashboard/no-deposit",
    "/deposit",
    "/legacy-deposit",
    "/deposit/bank-transfer",
    "/deposit/transfer-instruction",
    "/deposit-confirmation",
    "/disabled",
    PASSWORD_CHANGE_ROUTE,
];

export const DASHBOARD_ROUTES = [
    DASHBOARD_ROUTE,
    "/dashboard/assessment-failed",
    "/refer-a-friend",
    WALLET_ROUTE,
    "/trades",
    "/withdraw",
    "/live-success",
    "/manage-banking",
    "/account-statement",
    "/elective-professional",
    "/disabled",
    "/otp-settings",
    PASSWORD_CHANGE_ROUTE,
];
