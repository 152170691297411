const extractRootDomain = (url) => {
    const domain = new URL(url).hostname;
    const elems = domain.split(".");
    const iMax = elems.length - 1;

    const elem1 = elems[iMax - 1];
    const elem2 = elems[iMax];

    const isSecondLevelDomain = iMax >= 3 && (elem1 + elem2).length <= 5;
    return "." + (isSecondLevelDomain ? elems[iMax - 2] + "." : "") + elem1 + "." + elem2;
};

var _uf = _uf || {};
_uf.domain = extractRootDomain(window.origin);

_uf.initial_utm_params = true; // Enable the tracking of initial UTM Parameters

_uf.additional_params_map = {
    clickid: "AFFILIATE",
}; // Maps affiliate parameter to AFFILIATE form field

_uf.additional_initial_params_map = {
    clickid: "IAFFILIATE",
}; // Maps the initial affiliate parameter to IAFFILIATE form field

_uf.secure = true; // Enable secure cookies
_uf.sessionLength = 1; // In hours. Default is 1 hour
_uf.add_to_form = "first"; // 'none', 'all', 'first'. Default is 'all'
_uf.form_query_selector = 'form[action="/sign_up"]'; // custom form selector. Default is 'form', to select any form.
_uf.decode_uris = true; // Decode URLs before populating the form. e.g. use "https://" instead of "https%3A//".
// NOTE: This will decode the entire URL and, in rare cases, may have side effects.

_uf.utm_source_field = "utm_source";
_uf.utm_medium_field = "utm_medium";
_uf.utm_campaign_field = "utm_campaign";
_uf.utm_content_field = "utm_content";
_uf.utm_term_field = "utm_term";

_uf.initial_utm_params = true; // Enable the tracking of initial UTM Parameters
_uf.initial_utm_source_field = "initial_utm_source";
_uf.initial_utm_medium_field = "initial_utm_medium";
_uf.initial_utm_campaign_field = "initial_utm_campaign";
_uf.initial_utm_content_field = "initial_utm_content";
_uf.initial_utm_term_field = "initial_utm_term";

_uf.initial_referrer_field = "initial_utm_referrer";
_uf.last_referrer_field = "initial_utm_last_referrer";
_uf.initial_landing_page_field = "initial_utm_landing_page";

var UtmCookie;

UtmCookie = class UtmCookie {
    constructor(options = {}) {
        this._cookieNamePrefix = "_gpfx_";
        this._domain = options.domain;
        this._secure = options.secure || false;
        this._initialUtmParams = options.initialUtmParams || false;
        this._sessionLength = options.sessionLength || 1;
        this._cookieExpiryDays = options.cookieExpiryDays || 30;
        this._additionalParams = options.additionalParams || [];
        this._additionalInitialParams = options.additionalInitialParams || [];
        this._utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
        this.writeInitialReferrer();
        this.writeLastReferrer();
        this.writeIBReferrer();
        this.writeInitialLandingPageUrl();
        this.writeAdditionalInitialParams();
        this.setCurrentSession();
        if (this._initialUtmParams) {
            this.writeInitialUtmCookieFromParams();
        }
        if (this.additionalParamsPresentInUrl()) {
            this.writeAdditionalParams();
        }
        if (this.utmPresentInUrl()) {
            this.writeUtmCookieFromParams();
        }
        return;
    }

    createCookie(name, value, days, path, domain, secure) {
        var cookieDomain, cookieExpire, cookiePath, cookieSecure, date, expireDate;
        expireDate = null;
        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expireDate = date;
        }
        cookieExpire = expireDate != null ? "; expires=" + expireDate.toGMTString() : "";
        cookiePath = path != null ? "; path=" + path : "; path=/";
        cookieDomain = domain != null ? "; domain=" + domain : "";
        cookieSecure = secure ? "; secure" : "";
        document.cookie =
            this._cookieNamePrefix +
            name +
            "=" +
            escape(value) +
            cookieExpire +
            cookiePath +
            cookieDomain +
            cookieSecure;
    }

    readCookie(name) {
        var c, ca, i, nameEQ;
        nameEQ = this._cookieNamePrefix + name + "=";
        ca = document.cookie.split(";");
        i = 0;
        while (i < ca.length) {
            c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
            i++;
        }
        return null;
    }

    eraseCookie(name) {
        this.createCookie(name, "", -1, null, this._domain, this._secure);
    }

    getParameterByName(name) {
        var regex, regexS, results;
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        regexS = "[\\?&]" + name + "=([^&#]*)";
        regex = new RegExp(regexS);
        results = regex.exec(window.location.search);
        if (results) {
            return decodeURIComponent(results[1].replace(/\+/g, " "));
        } else {
            return "";
        }
    }

    additionalParamsPresentInUrl() {
        var j, len, param, ref;
        ref = this._additionalParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            if (this.getParameterByName(param)) {
                return true;
            }
        }
        return false;
    }

    utmPresentInUrl() {
        var j, len, param, ref;
        ref = this._utmParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            if (this.getParameterByName(param)) {
                return true;
            }
        }
        return false;
    }

    writeCookie(name, value, domain = this._domain) {
        this.createCookie(name, value, this._cookieExpiryDays, null, domain, this._secure);
    }

    writeCookieOnce(name, value) {
        var existingValue;
        existingValue = this.readCookie(name);
        if (!existingValue) {
            this.writeCookie(name, value);
        }
    }

    writeAdditionalParams() {
        var j, len, param, ref, value;
        ref = this._additionalParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            value = this.getParameterByName(param);
            this.writeCookie(param, value);
        }
    }

    writeAdditionalInitialParams() {
        var j, len, name, param, ref, value;
        ref = this._additionalInitialParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            name = "initial_" + param;
            value = this.getParameterByName(param) || null;
            this.writeCookieOnce(name, value);
        }
    }

    writeUtmCookieFromParams() {
        var j, len, param, ref, value;
        ref = this._utmParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            value = this.getParameterByName(param);
            this.writeCookie(param, value);
        }
    }

    writeInitialUtmCookieFromParams() {
        var j, len, name, param, ref, value;
        ref = this._utmParams;
        for (j = 0, len = ref.length; j < len; j++) {
            param = ref[j];
            name = "initial_" + param;
            value = this.getParameterByName(param) || null;
            this.writeCookieOnce(name, value);
        }
    }

    _sameDomainReferrer(referrer) {
        var hostname;
        hostname = document.location.hostname;
        return referrer.indexOf(this._domain) > -1 || referrer.indexOf(hostname) > -1;
    }

    _isInvalidReferrer(referrer) {
        return referrer === "" || referrer === void 0;
    }

    //BK Channel INITIAL Referrer START
    writeInitialReferrer() {
        var value;
        value = document.referrer;
        var urlvalue;
        urlvalue = window.location.href;
        //facebook
        if (value.includes("facebook")) {
            value = "facebook";
        }
        //youtube
        if (value.includes("youtube")) {
            value = "youtube";
        }
        //twitter
        if (value.includes("t.co") || value.includes("twitter")) {
            value = "twitter";
        }
        //linkedin
        if (value.includes("linkedin")) {
            value = "linkedin";
        }
        //insta
        if (value.includes("instagram")) {
            value = "instagram";
        }
        //bing
        if (value.includes("bing")) {
            value = "bing";
        }
        //organic search
        if (value.includes("google") && !urlvalue.includes("gclid=")) {
            value = "organic search";
        }
        //ppc google
        if (value.includes("google") && urlvalue.includes("gclid=")) {
            value = "google ppc";
        }
        //ppc bing
        if (value.includes("bing") && urlvalue.includes("utm_medium=cpc")) {
            value = "bing ppc";
        }
        if (this._sameDomainReferrer(value)) {
            value = "direct";
        }
        this.writeCookieOnce("referrer", value);
    }
    //BK Channel INITIAL Referrer END

    //BK Channel Last Referrer START
    writeLastReferrer() {
        var value;
        value = document.referrer;
        var urlvalue;
        urlvalue = window.location.href;
        //facebook
        if (value.includes("facebook")) {
            value = "facebook";
        }
        //youtube
        if (value.includes("youtube")) {
            value = "youtube";
        }
        //twitter
        if (value.includes("t.co") || value.includes("twitter")) {
            value = "twitter";
        }
        //linkedin
        if (value.includes("linkedin")) {
            value = "linkedin";
        }
        //insta
        if (value.includes("instagram")) {
            value = "instagram";
        }
        //bing
        if (value.includes("bing")) {
            value = "bing";
        }
        //organic search
        if (value.includes("google") && !urlvalue.includes("gclid=")) {
            value = "organic search";
        }
        //ppc google
        if (value.includes("google") && urlvalue.includes("gclid=")) {
            value = "google ppc";
        }
        //ppc bing
        if (value.includes("bing") && urlvalue.includes("utm_medium=cpc")) {
            value = "bing ppc";
        }
        if (this._sameDomainReferrer(value)) {
            value = "direct";
        }

        this.writeCookie("last_referrer", value);
    }
    //BK Channel Last Referrer END

    //BK Channel IB Referrer START
    writeIBReferrer() {
        var value;
        value = this.getParameterByName("clickId");
        if (value) {
            this.writeCookie("ib_referrer", value, extractRootDomain(window.origin));
        }
    }

    //BK Channel IB Referrer END

    writeInitialLandingPageUrl() {
        var value;
        value = this.cleanUrl();
        if (value) {
            this.writeCookieOnce("initial_landing_page", value);
        }
    }

    initialReferrer() {
        return this.readCookie("referrer");
    }

    lastReferrer() {
        return this.readCookie("last_referrer");
    }

    initialLandingPageUrl() {
        return this.readCookie("initial_landing_page");
    }

    incrementVisitCount() {
        var cookieName, existingValue, newValue;
        cookieName = "visits";
        existingValue = parseInt(this.readCookie(cookieName), 10);
        if (isNaN(existingValue)) {
            newValue = 1;
        } else {
            newValue = existingValue + 1;
        }
        this.writeCookie(cookieName, newValue);
    }

    visits() {
        return this.readCookie("visits");
    }

    setCurrentSession() {
        var cookieName, existingValue;
        cookieName = "current_session";
        existingValue = this.readCookie(cookieName);
        if (!existingValue) {
            this.createCookie(cookieName, "true", this._sessionLength / 24, null, this._domain, this._secure);
            this.incrementVisitCount();
        }
    }

    cleanUrl() {
        var cleanSearch;
        cleanSearch = window.location.search
            .replace(/utm_[^&]+&?/g, "")
            .replace(/&$/, "")
            .replace(/^\?$/, "");
        return window.location.origin + window.location.pathname + cleanSearch + window.location.hash;
    }
};

var UtmForm, _uf;

UtmForm = class UtmForm {
    constructor(options = {}) {
        this._utmParamsMap = {};
        this._utmParamsMap.utm_source = options.utm_source_field || "USOURCE";
        this._utmParamsMap.utm_medium = options.utm_medium_field || "UMEDIUM";
        this._utmParamsMap.utm_campaign = options.utm_campaign_field || "UCAMPAIGN";
        this._utmParamsMap.utm_content = options.utm_content_field || "UCONTENT";
        this._utmParamsMap.utm_term = options.utm_term_field || "UTERM";
        this._initialUtmParamsMap = {};
        this._initialUtmParamsMap.initial_utm_source = options.initial_utm_source_field || "IUSOURCE";
        this._initialUtmParamsMap.initial_utm_medium = options.initial_utm_medium_field || "IUMEDIUM";
        this._initialUtmParamsMap.initial_utm_campaign = options.initial_utm_campaign_field || "IUCAMPAIGN";
        this._initialUtmParamsMap.initial_utm_content = options.initial_utm_content_field || "IUCONTENT";
        this._initialUtmParamsMap.initial_utm_term = options.initial_utm_term_field || "IUTERM";
        this._additionalParamsMap = options.additional_params_map || {};
        this._additionalInitialParamsMap = options.additional_initial_params_map || {};
        this._initialReferrerField = options.initial_referrer_field || "IREFERRER";
        this._lastReferrerField = options.last_referrer_field || "LREFERRER";
        this._initialLandingPageField = options.initial_landing_page_field || "ILANDPAGE";
        this._visitsField = options.visits_field || "VISITS";
        // Options:
        // "none": Don't add any fields to any form
        // "first": Add UTM and other fields to only first form on the page
        // "all": (Default) Add UTM and other fields to all forms on the page
        this._addToForm = options.add_to_form || "all";
        this._formQuerySelector = options.form_query_selector || "form";
        this._decodeURIs = options.decode_uris || false;
        this.utmCookie = new UtmCookie({
            domain: options.domain,
            secure: options.secure,
            sessionLength: options.sessionLength,
            cookieExpiryDays: options.cookieExpiryDays,
            initialUtmParams: options.initial_utm_params,
            additionalParams: Object.getOwnPropertyNames(this._additionalParamsMap),
            additionalInitialParams: Object.getOwnPropertyNames(this._additionalInitialParamsMap),
        });
        this.addAllFields();
    }

    addAllFields() {
        var allForms, i, len;
        allForms = document.querySelectorAll(this._formQuerySelector);
        if (this._addToForm === "none") {
            len = 0;
        } else if (this._addToForm === "first") {
            len = Math.min(1, allForms.length);
        } else {
            len = allForms.length;
        }
        i = 0;
        while (i < len) {
            this.addAllFieldsToForm(allForms[i]);
            i++;
        }
    }

    addAllFieldsToForm(form) {
        var cookieName, fieldName, param, ref, ref1, ref2, ref3;
        if (form && !form._utm_tagged) {
            form._utm_tagged = true;
            ref = this._utmParamsMap;
            for (param in ref) {
                fieldName = ref[param];
                this.addFormElem(form, fieldName, this.utmCookie.readCookie(param));
            }
            ref1 = this._initialUtmParamsMap;
            for (param in ref1) {
                fieldName = ref1[param];
                this.addFormElem(form, fieldName, this.utmCookie.readCookie(param));
            }
            ref2 = this._additionalParamsMap;
            for (param in ref2) {
                fieldName = ref2[param];
                this.addFormElem(form, fieldName, this.utmCookie.readCookie(param));
            }
            ref3 = this._additionalInitialParamsMap;
            for (param in ref3) {
                fieldName = ref3[param];
                cookieName = "initial_" + param;
                this.addFormElem(form, fieldName, this.utmCookie.readCookie(cookieName));
            }
            this.addFormElem(form, this._initialReferrerField, this.utmCookie.initialReferrer());
            this.addFormElem(form, this._lastReferrerField, this.utmCookie.lastReferrer());
            this.addFormElem(form, this._initialLandingPageField, this.utmCookie.initialLandingPageUrl());
            this.addFormElem(form, this._visitsField, this.utmCookie.visits());
        }
    }

    addFormElem(form, fieldName, fieldValue) {
        this.insertAfter(this.getFieldEl(fieldName, fieldValue), form.lastChild);
    }

    // NOTE: This should be called for each form element or since it
    // attaches itself to the first form
    getFieldEl(fieldName, fieldValue) {
        var fieldEl;
        fieldEl = document.createElement("input");
        fieldEl.type = "hidden";
        fieldEl.name = fieldName;
        fieldEl.value = this._decodeURIs ? decodeURIComponent(fieldValue) : fieldValue;
        return fieldEl;
    }

    insertAfter(newNode, referenceNode) {
        return referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
};

_uf = window._uf || {};

window.UtmForm = new UtmForm(_uf);
