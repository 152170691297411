import { initializeApp } from "firebase/app";
import {
    browserLocalPersistence,
    browserSessionPersistence,
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signInWithPopup,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    updatePassword,
    onAuthStateChanged,
} from "firebase/auth";

/**
 * Initialize Firebase auth.
 */
export const firebaseApp = initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    // authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    authDomain: import.meta.env.VITE_FIREBASE_CUSTOM_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
});

export const firebaseAuth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const auth = getAuth();

// whenever a user interacts with the provider, we force them to select an account
googleProvider.setCustomParameters({
    prompt: "select_account ",
});

export const firebaseUtils = {
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    browserLocalPersistence,
    browserSessionPersistence,
    GoogleAuthProvider,
    firebaseApp,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    onAuthStateChanged,
    signInWithPopup,
};
