import React, { useContext } from "react";
import { Sun, Moon } from "react-feather";

import { AprButton } from "../Button";

import { ThemeContext } from "./ThemeContext";

export const ToggleTheme = () => {
    const { toggleTheme, theme } = useContext(ThemeContext);

    return (
        <AprButton type="button" variant="ghost" onClick={toggleTheme} className="p-2 text-themeColor">
            {theme === "dark" ? <Sun className="h-4 w-4" /> : <Moon className="h-4 w-4" />}
        </AprButton>
    );
};
