import type { ElementType } from "react";
import type {
    AprSizeKeys,
    AprVariantKeys,
    CompoundComponentWithRef,
    InheritableElementProps,
    PolymorphicProps,
} from "@/types";

import { forwardRef, createElement } from "react";
import { getChildByType } from "react-nanny";

import { tx } from "@/libs/tailwindMerge";

import { AprSpinner } from "@/components/Spinner";

export type AprButtonProps = InheritableElementProps<
    "button",
    {
        loading?: boolean;
        size?: AprSizeKeys;
        variant?: AprVariantKeys;
    }
>;

export const AprButtonIcon = ({ as, ...props }: PolymorphicProps<ElementType>) => {
    const iconProps = {
        "aria-hidden": true,
        ...props,
    };

    if (as) {
        return createElement(as, iconProps);
    }

    throw new Error("PdsButton.Icon must have either an `as` prop or a `children` prop");
};

export const AprButton = forwardRef<HTMLButtonElement, AprButtonProps>(
    (
        {
            type = "button",
            disabled = false,
            loading = false,
            variant = "primary",
            size = "medium",
            className,
            children,
            ...props
        },
        ref
    ) => {
        const iconEl = getChildByType(children, AprButtonIcon);
        const isIconButton = Boolean(iconEl);

        const content = isIconButton ? iconEl : children;

        return (
            <button
                ref={ref}
                type={type}
                aria-busy={loading ? "true" : undefined}
                disabled={disabled || loading}
                className={tx(
                    "relative rounded-full border font-semibold",
                    "focusable transition duration-300 motion-reduce:transition-none",
                    {
                        "shadow-primary/50 border-primary-300 bg-primary !text-white hover:border-primary-100 hover:shadow-4xl hover:shadow-primary-100 hover:dark:shadow-primary":
                            variant === "primary",
                        "border-secondary bg-secondary hover:border-black hover:bg-secondary-500 hover:dark:bg-black":
                            variant === "secondary",
                        "border border-background bg-background backdrop-blur-lg hover:border-black hover:dark:border-white":
                            variant === "tertiary",
                        "border-success-100 bg-success-700 dark:hover:shadow-3xl dark:hover:shadow-success-300":
                            variant === "success",
                        "shadow-error/50 border-foreground bg-error-25 text-error-300 hover:border-error hover:shadow-error-300 dark:border-error-25":
                            variant === "error",
                        "border-transparent bg-transparent text-foreground hover:border-background hover:bg-background":
                            variant === "ghost",
                    },
                    {
                        "cursor-wait": loading,
                        "cursor-not-allowed border-foreground bg-foreground-500 !text-foreground shadow-none hover:border-foreground hover:bg-foreground-500 hover:shadow-none":
                            disabled,
                    },
                    {
                        "px-5 py-1 text-xs": size === "small",
                        "px-5 py-2 text-sm": size === "medium",
                        "px-5 py-3 text-lg": size === "large",
                    },
                    {
                        "p-0.5": isIconButton && size === "small",
                        "p-1": isIconButton && size === "medium",
                        "p-3": isIconButton && size === "large",
                    },
                    className
                )}
                {...props}
            >
                {loading && (
                    <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <AprSpinner />
                    </span>
                )}
                {loading ? <span className="invisible contents">{content}</span> : content}
            </button>
        );
    }
) as CompoundComponentWithRef<{ Icon: typeof AprButtonIcon }, AprButtonProps, HTMLButtonElement>;

AprButton.Icon = AprButtonIcon;
